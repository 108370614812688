import React, { useEffect, useState } from 'react';
import { PaginatedChangeLogs } from '../../Types';
import { Button, Modal, Table } from 'react-bootstrap';
import { customAxiosFetch } from '../../helpers/customFetch';
import { ChangeLogsPaginationControls } from './ChangeLogsPaginationControls';
import { useAppSelector } from '../../hooks';
import { useDispatch } from 'react-redux';
import { clearChangeLogsSlice, setChangeLogs } from '../../ReduxSlices/changeLogsSlice';

const ChangeLogs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [changeLogs, seChangeLogs] = useState<PaginatedChangeLogs>();
  const changeLogsMetadata = useAppSelector((state) => state.changeLogs.changeLogsMetadata);
  const dispatch = useDispatch();

  const handleCloseModal = async () => {
    dispatch(clearChangeLogsSlice());
  };

  const getChangeLogs = async (pageNumber = 1) => {
    customAxiosFetch<PaginatedChangeLogs>({
      endpoint: `/ChangeLog/${changeLogsMetadata.entityName}/${changeLogsMetadata.entityId}?page=${pageNumber}`,
      callback: (result) => {
        if (!result) return;
        setIsLoading(false);
        seChangeLogs(result);
      }
    });
  };

  useEffect(() => {
    getChangeLogs();
  }, []);

  return (
    !isLoading && (
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        show={true}
        centered
        onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {changeLogsMetadata.entityName} history
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {changeLogs !== null && changeLogs.changeLogs.length !== 0 ? (
            <Table responsive striped bordered>
              <thead>
                <tr>
                  <th>Property name</th>
                  <th>Action</th>
                  <th>New value</th>
                  <th>Old value</th>
                  <th>Inserted by</th>
                </tr>
              </thead>
              <tbody>
                {changeLogs.changeLogs.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.propertyName}</td>
                      <td>{item.action}</td>
                      <td>{item.newValue}</td>
                      <td>{item.oldValue}</td>
                      <td>{item.insertedBy}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <h3 className="my-5 text-center">No history available</h3>
          )}
          {changeLogs !== null && changeLogs.changeLogs.length !== 0 && (
            <ChangeLogsPaginationControls
              getHistory={getChangeLogs}
              currentPage={changeLogs.currentPage}
              numberOfPages={changeLogs.numberOfPages}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default ChangeLogs;
