import React from 'react';
import { Pagination } from 'react-bootstrap';
interface IProps {
  getHistory: (pageNumber) => void;
  currentPage: number;
  numberOfPages: number;
}

export const ChangeLogsPaginationControls = ({
  getHistory,
  currentPage,
  numberOfPages
}: IProps) => {
  return (
    <Pagination className="justify-content-center">
      <Pagination.First disabled={currentPage === 1} onClick={() => getHistory(1)} />
      <Pagination.Prev disabled={currentPage === 1} onClick={() => getHistory(currentPage - 1)} />
      <Pagination.Next
        disabled={currentPage === numberOfPages}
        onClick={() => {
          getHistory(currentPage + 1);
        }}
      />
      <Pagination.Last
        disabled={currentPage === numberOfPages}
        onClick={() => getHistory(numberOfPages)}
      />
    </Pagination>
  );
};
